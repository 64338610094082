import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { User } from '../_models/user';

import { AuthenticationService } from '../_services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal
  ) {}

  exceptions = ['search', 'rooms'];

  exceptionExists(route) {
    console.log(`AuthGuard:exceptionExists Checking if exception exists for route ${route}`);
    if (this.exceptions.indexOf(route) >= -1) {
      console.log('AuthGuard:exceptionExists Exception exists');
      return true;
    } else {
      console.log("AuthGuard:exceptionExists Exception doesn't exist");
      return false;
    }
  }

  canGoToRoute(user: User, route): boolean {
    let permissions = this.authenticationService.userPermissions;
    if (!permissions) {
      const userFromLocalStorage: User = JSON.parse(
        localStorage.getItem('currentUser')
      );
      permissions = userFromLocalStorage.role.permissions;
      this.authenticationService.permissions$.next(permissions);
    }

    console.log('route:', route, permissions[route]);
    if (
      this.exceptionExists(route) ||
      (permissions[route] && permissions[route]['read'] === true)
    ) {
      return true;
    } else {
      return false;
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('AuthGuard:canActivate: Checking if user can go to route');
    this.modalService.dismissAll();
    let token = route.queryParams['token']    
    if (token) {
      console.log('AuthGuard:canActivate: Token:', token);
      return this.authenticationService.verifyToken(token).pipe(
        catchError(err => {
          console.log('Handling error locally and rethrowing it...', err);
          this.router.navigate(['/auth/login']);
          return of(false);
        })
      );
    } else {
      console.log('AuthGuard:canActivate: No Token');
    }
    const currentUser = this.authenticationService.currentUserValue;
    console.log('AuthGuard:canActivate: currentUser', currentUser);
    if (currentUser) {
      if (
        this.canGoToRoute(currentUser, state.url.split('/')[1].split('?')[0])
      ) {
        return true;
      } else if (state.url.split('/')[1] === 'dashboard') {
        this.router.navigate(['/buildings']);
        return true;
      } else {
        this.router.navigate(['/not-allowed']);
        return true;
      }
    }
    console.log('AuthGuard:canActivate: Not logged in');
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth/login']);
    return true;
  }
}
