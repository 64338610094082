export const environment = {
  production: true,
  apiPrefix: 'https://',
  apiSuffix: '.api.creevx.com/api',
  envVar: {
    NAME: 'DAA',
    LOGO: 'https://semvar-daa-general.s3.eu-west-1.amazonaws.com/dark-logo.png',
    LOGO_WHITE: 'https://semvar-daa-general.s3.eu-west-1.amazonaws.com/white-logo.png',
    PRIMARY_COLOR: '#357366',
    FAVICON: 'https://semvar-daa-general.s3.eu-west-1.amazonaws.com/favicon.png',
      TENANT: "daa"
},
};
